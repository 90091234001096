/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useState, useEffect, Suspense } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, navigate } from "gatsby"

import Header from "./header"
import "../scss/layout.css"
import Footer from "./Footer"
import Ads from "./ADS"
import { Context } from "./context"
import ComparisonModal from "./ComparisonModal"
import ProductSelectorModal from "./ProductSelectorModal"
import { GatsbyImage } from "gatsby-plugin-image"

const SaleBlackFriday = React.lazy(() => import("./SaleBlackFriday"))

function getInitialState() {
  if (typeof window !== `undefined`) {
    const cartData = localStorage.getItem("cartData")
    return cartData ? JSON.parse(cartData) : []
  }
}

function getCompareInitialState() {
  if (typeof window !== `undefined`) {
    const comparisonData = localStorage.getItem("comparisonData")
    return comparisonData
      ? JSON.parse(comparisonData)
      : [null, null, null, null]
  }
}

const defaultCompareList = [null, null, null, null]

const Layout = ({
  children,
  location,
  productURL,
  noHeader,
  noFooter,
  noSticky,
  noADS,
  noFooterForm = false,
}) => {
  const isSSR = typeof window === "undefined"
  const [cartData, setCartData] = useState(getInitialState)
  const [comparisonData, setComparisonData] = useState(defaultCompareList)
  const [compareProducts, setCompareProducts] = useState([])
  const [comparisonModal, setComparisonModal] = useState(false)
  const [selectProductInfo, setSelectProductInfo] = useState({
    isVisible: false,
    onSelect: null,
    onToggle: null,
  })
  const isComparePage =
    location.pathname === "/compare" ||
    location.pathname.startsWith("/compare/")

  useEffect(() => {
    if (typeof window !== `undefined`) {
      const cd = localStorage.getItem("comparisonData")
      setComparisonData(cd ? JSON.parse(cd) : defaultCompareList)
    }
  }, [])

  useEffect(() => {
    localStorage.setItem("cartData", JSON.stringify(cartData))
  }, [cartData, setCartData])

  useEffect(() => {
    localStorage.setItem("comparisonData", JSON.stringify(comparisonData))
    const ps = comparisonData.filter(p => p)
    setCompareProducts(ps)
  }, [comparisonData])

  useEffect(() => {
    document.body.style.overflow = comparisonModal ? "hidden" : "auto"
  }, [comparisonModal])

  useEffect(() => {
    document.head.insertAdjacentHTML(
      "beforeend",
      `<style id="chat-widget-hide-style">#chat-widget-container { display: none !important;}</style>`
    )
    const chatWidgetElement = document.getElementById("chat-widget-hide-style")
    window.addEventListener("scroll", function () {
      if (window.pageYOffset > 800 && chatWidgetElement) {
        chatWidgetElement.remove()
      }
    })
  }, [])

  const removeProduct = productId => {
    const pId = comparisonData.findIndex(p => p && p.id === productId)
    const ncd = [...comparisonData]
    ncd[pId] = null
    setComparisonData(ncd)
  }

  const addProductToComparison = (type, product, no) => {
    if (!comparisonData || !comparisonData.length) {
      if (no === undefined) {
        setComparisonData([product, null, null, null])
      } else {
        const pc = new Array(4).fill(null)
        pc[no] = product
        setComparisonData(pc)
      }
      if (isComparePage && type === "card") {
        alert("Product added to the comparison list")
      }
      if (!isComparePage) setComparisonModal(true)
      return
    }

    const newData = [...comparisonData]
    let found = newData.findIndex(item => item && item.id === product.id)
    if (found >= 0) {
      newData[found] = product
      setComparisonData(newData)
      alert("Product already added!")
      return
    }

    if (no >= 0) {
      const pc = [...comparisonData]
      pc[no] = product
      setComparisonData(pc)
    } else {
      const newData = [...comparisonData]
      const k = newData.findIndex(item => item === null)
      if (k === -1) {
        if (newData.length >= 4) {
          alert("You cannot add more than 4 products to compare")
          return
        }
        newData.push(product)
      } else {
        newData[k] = product
      }
      setComparisonData(newData)
      if (!isComparePage) setComparisonModal(true)
      if (isComparePage && type === "card") {
        alert("Product added to the comparison list")
      }
    }
  }

  const selectProduct = () => {
    return new Promise((resolve, reject) => {
      setSelectProductInfo({
        onSelect: p => {
          setSelectProductInfo({
            isVisible: false,
            onSelect: null,
            onToggle: null,
          })
          resolve(p)
        },
        onToggle: p => {
          setSelectProductInfo({
            isVisible: false,
            onSelect: null,
            onToggle: null,
          })
          reject(p)
        },
        isVisible: true,
      })
    })
  }

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
      allContentfulProduct {
        edges {
          node {
            productSku
            productName
            url
            width
            height
            length
          }
        }
      }
    }
  `)

  let count = 0
  if (comparisonData && comparisonData.length) {
    count = comparisonData.filter(p => p).length
  }

  return (
    <Context.Provider
      value={{
        cartData,
        setCartData,
        comparisonData,
        compareProducts,
        removeProduct,
        addProductToComparison,
        selectProduct,
      }}
    >
      {!noHeader && (
        <Header
          siteTitle={data.site.siteMetadata?.title || `Title`}
          productData={data.allContentfulProduct.edges}
          location={location}
        />
      )}
      {!noADS ? (
        <main>
          {children} <Ads />
        </main>
      ) : (
        <>{children}</>
      )}
      {!noFooter && <Footer location={location} noFooterForm={noFooterForm} />}
      {!isSSR && (
        <Suspense fallback="">
          {location.pathname !== productURL && !noSticky && (
            <SaleBlackFriday location={location} />
          )}
        </Suspense>
      )}

      {/* <ComparisonModal
        isVisible={comparisonModal}
        onToggle={() => setComparisonModal(false)}
      />

      <ProductSelectorModal
        isVisible={selectProductInfo.isVisible}
        onToggle={selectProductInfo.onToggle || null}
        onSelect={selectProductInfo.onSelect || null}
      />

      {count > 0 && !isComparePage && (
        <div className="comparison-marker" onClick={() => navigate("/compare")}>
          <span>Comparison</span>
          <div className="first-product-img">
            <GatsbyImage
              image={comparisonData[0]?.productImages?.gatsbyImageData}
              alt={comparisonData[0]?.productName}
            />
          </div>
          <div className="comparison-badge">{count}</div>
        </div>
      )} */}
    </Context.Provider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout